<template>
  <div class="user-transactions fixed-draggable-dynamic-table-wrapper-height">
    <draggable-dynamic-table ref="userTransactions"
                             class="user-transactions top-zero-radius"
                             :options="options"
                             :columns="columnsLabel"
                             :data="data"
                             @load:more="getUserTransactions()"/>
  </div>
</template>

<script>
import axios from 'axios'
import DraggableDynamicTable from '@/components/draggableDynamicTable/draggableDynamicTable'
import {getUserTransaction} from '@/http/requests/users/users'

export default {
  name: 'profileTransactions',
  components: {DraggableDynamicTable},
  data () {
    return {
      options: {
        id: 'userTransactions',
        rowKeyField: 'docNumber'
      },
      columnsLabel: [
        {
          field: 'balance',
          i18n: 'users.transactions.table.header.balance',
          width: '130px',
          minWidth: 130,
          locked: true,
          sortable: true
        },
        {
          field: 'price',
          i18n: 'users.transactions.table.header.price',
          width: '130px',
          minWidth: 130,
          locked: true,
          sortable: true
          /*footer: {
            type: 'auto-sum',
            textType: 'price'
          }*/
        },
        {
          field: 'documentation',
          i18n: 'users.transactions.table.header.documentation',
          width: '120px',
          minWidth: 120,
          align: 'center',
          locked: true,
          sortable: true
        },
        {
          field: 'date',
          i18n: 'users.transactions.table.header.date',
          align: 'center',
          width: '70px',
          minWidth: 70,
          locked: true,
          sortable: true
        },
        {
          field: 'docNumber',
          i18n: 'users.transactions.table.header.docNumber',
          width: '80px',
          minWidth: 80,
          align: 'center',
          locked: true,
          sortable: true,
          footer: {}
        }
      ],
      data: [],
      filters: {},
      sorts: [],
      page: 1,
      loadingTimer: 0,
      endedList: false,
      actions: [
        {
          leftToolbar: [
            {
              id: 'settingTable',
              icon: 'SETTING',
              iconPack: 'useral'
            },
            {
              id: {name: 'Profile'},
              type: 'link',
              icon: 'CHEVRON_LEFT',
              iconPack: 'useral'
            }
          ]
        }
      ]
    }
  },
  created () {
    this.$nextTick(() => {
      this.$store.dispatch('updateNavbarActions', this.actions[0])
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
    })

    this.getUserTransactions()
  },
  methods: {
    getUserTransactions () {
      clearTimeout(this.loadingTimer)
      this.loadingTimer = setTimeout(() => {
        if (!this.endedList) {
          if (this.$refs.userTransactions && this.data.length === 0) {
            this.$refs.userTransactions.loadMoreStatus = 'FirstLoad'
          } else if (this.$refs.userTransactions && this.data.length > 0) {
            this.$refs.userTransactions.loadMoreStatus = 'Loading'
          }

          const user_id = this.$store.getters['auth/getActiveUser'].id
          getUserTransaction(user_id, this.page, this.filters, this.sorts).then(response => {
            this.endedList = response.data.data.length === 0

            const userTransactions = response.data.data
            for (let i = 0; i < userTransactions.length; i++) {
              this.data.push({
                docNumber: userTransactions[i].documrnt_id || userTransactions[i].document_id,
                date: userTransactions[i].created_at,
                documentation: userTransactions[i].description,
                price: {
                  type: 'price',
                  classes: userTransactions[i].price > 0 ? 'text-danger' : 'text-success',
                  value: userTransactions[i].price * -1
                },
                balance: {
                  type: 'price',
                  classes: userTransactions[i].balance > 0 ? 'text-danger' : 'text-success',
                  value: userTransactions[i].balance * -1
                },
                route: {name: 'userTransaction',
                  params: {
                    id: this.$route.params.id,
                    docId: userTransactions[i].documrnt_id || userTransactions[i].document_id
                  }
                }
              })
            }

            this.page = response.data.pagination.current_page + 1
            if (response.data.pagination.current_page === 1) {
              const row_index = this.columnsLabel.map((e) => {
                return e.field
              }).indexOf('docNumber')
              this.columnsLabel[row_index].footer.value = response.data.pagination.total
            }

            if (this.$refs.userTransactions) this.$refs.userTransactions.loadMoreStatus = ''
          }).catch((error) => {
            if (this.$refs.userTransactions && !axios.isCancel(error)) this.$refs.userTransactions.loadMoreStatus = 'Danger'
          })
        }
      }, 500)
    }
  }
}
</script>

<style lang="scss" scoped>
.user-transactions {
}
</style>
